"use strict";
import '/src/css/style.scss';
import 'lazysizes';

import cssVars from 'css-vars-ponyfill';

cssVars({
  rootElement: document // default
});

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupGtagPlugin from 'swup-gtag-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';

import InfiniteScroll from "infinite-scroll";

import Swiper from 'swiper';
import { Navigation, Pagination, A11y, Keyboard } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

//  Check if Javascript is enabled  
function checkJavascriptEnabled() {
  document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, '') + ' js ';
}

//  Smooth scrollto
function scrollToAnchorSmoothly() {
  if (document.querySelector('a[href^=#')) {
    var links = document.querySelectorAll('a[href^=#');

    links.forEach(function (link) {
      link.addEventListener('click', function (event) {
        event.preventDefault();
        var targetElement = event.target || event.srcElement;
        var url = this.getAttribute("href");
        var hash = url.substring(url.indexOf('#') + 1);
        var destinationElement = document.getElementById(hash);
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: destinationElement.offsetTop
        });
      })
    })
  }
}

/* Variables  --------------------*/
const html = document.getElementsByTagName('html')[0];
let loader = document.querySelector('.c-loader');

/* Close menu --------------------*/
function closeMenu() {
  html.classList.remove('has-navigation-open');
}

/* Menu toggle --------------------*/
function setupMenu() {
  var menuToggle = document.querySelector('.js-navigation__toggle');
  var menuLinks = document.querySelectorAll('.c-navigation a');
  function toggle() {
      html.classList.toggle('has-navigation-open');
  }

  function keyboardClose(evt) {
      evt = evt || window.event;
      let isEscape = false;
      if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
          close();
      }
  }
  
  menuToggle.addEventListener('click', toggle);
  document.addEventListener('keypress', keyboardClose);
}


/* Loader  --------------------*/
function hideLoader() {
  if (loader) {
    html.classList.add('is-showing-intro');
  }

  loader.addEventListener('click', function () {
    loader.classList.add('is-hidden');
    html.classList.remove('is-showing-intro');
    html.classList.add('is-passed-intro');
  });

  let delayInMilliseconds = 2000; 

  setTimeout(function() {
    loader.classList.add('is-hidden');
    html.classList.remove('is-showing-intro');
    html.classList.add('is-passed-intro');
  }, delayInMilliseconds);
}


/* Paginate --------------------*/
function paginate() {
  if ( document.querySelector('.js-loadmore')) {

    let button = document.querySelector('.js-loadmore');
    let buttonLabel = button.childNodes[0].nodeValue;

    let elem = document.querySelector('.infinitescroll');
    var infScroll = new InfiniteScroll( elem, {
      'path': '.js-loadmore',
      'append': '.c-index__item',
      'history': 'push',
      'button': '.js-loadmore',
      'scrollThreshold': false
    });

    infScroll.on('request', function() {
      button.innerHTML = 'Loading…';
    })

    infScroll.on('load', function() {
      button.innerHTML = buttonLabel;
    })
  }
}

/* Carousel  --------------------*/

function carousel() {
  if ( document.querySelector(".swiper")) {
    const swiper = new Swiper(".swiper", {
      modules: [Navigation, Pagination, A11y, Keyboard],
      loop: true,
      spaceBetween: 50,
      grabCursor: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      // navigation: {
      //   nextEl: '.js-carousel-next',
      //   prevEl: '.js-carousel-prev',
      // },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

  }

}


/* Init --------------------*/

function init() {
  checkJavascriptEnabled();
  hideLoader();
  window.scroll({ top: 0 });
  paginate();
  carousel();
  closeMenu();
}

document.addEventListener("touchstart", function () {}, true);

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init); // Document still loading so DomContentLoaded can still fire :)
} else {
  init();
}

if (!isIE11) {
  const swup = new Swup({
    plugins: [
      new SwupPreloadPlugin(),
      new SwupBodyClassPlugin(),
      new SwupScriptsPlugin(),
      new SwupScrollPlugin({
          offset: () => document.querySelector('.c-masthead').offsetHeight,
          animateScroll: false
        })
    ]
  });
  swup.on('contentReplaced', init);
  swup.on('samePage', closeMenu );
}


setupMenu();
// init();